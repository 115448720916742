import * as React from 'react';

import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';

import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
// import { useForm } from 'react-final-form';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles({
  container: { minWidth: '35em' },
  rightAlignedCell: { textAlign: 'right' },
  boldCell: { fontWeight: 'bold' },
});

const Totals = ({ record }) => {
  const classes = useStyles();
  const translate = useTranslate();
  // const form = useForm();
  // const formdata = form.getState().values;

  return (
    <Table className={classes.container}>
      <TableBody>
        {/* <TableRow>
          <TableCell>
            {translate('resources.commands.fields.items.sum')}
          </TableCell>
          <TableCell className={classes.rightAlignedCell}>
            {record?.total_ex_taxes.toLocaleString(undefined, {
              style: 'currency',
              currency: 'USD',
            })}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            {translate('resources.commands.fields.items.delivery')}
          </TableCell>
          <TableCell className={classes.rightAlignedCell}>
            {record?.delivery_fees.toLocaleString(undefined, {
              style: 'currency',
              currency: 'USD',
            })}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            {translate('resources.commands.fields.items.taxes')} (
            {record?.tax_rate.toLocaleString(undefined, {
              style: 'percent',
            })}
            )
          </TableCell>
          <TableCell className={classes.rightAlignedCell}>
            {record?.taxes.toLocaleString(undefined, {
              style: 'currency',
              currency: 'USD',
            })}
          </TableCell>
        </TableRow> */}
        <TableRow>
          <TableCell className={classes.boldCell}>
            {translate('resources.commands.fields.items.total')}
          </TableCell>
          <TableCell
            className={classnames(classes.boldCell, classes.rightAlignedCell)}
          >
            {(record?.totalSumm).toLocaleString(undefined, {
              style: 'currency',
              currency: 'USD',
            })}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default Totals;
