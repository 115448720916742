import React from 'react';
import { useMediaQuery } from '@material-ui/core';

import ExchangeRate from './ExchangeRate';
import Items from './Items';
import NewUsers from './NewUsers';

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column', marginTop: '1rem' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
};

const Dashboard = () => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return isXSmall ? (
    <div>
      <div style={styles.flexColumn}>
        <div style={styles.flex}>
          <ExchangeRate />
          <Items />
        </div>
        <div style={styles.singleCol}>
          <NewUsers />
        </div>
      </div>
    </div>
  ) : isSmall ? (
    <div style={styles.flexColumn}>
      <div style={styles.flex}>
        <ExchangeRate />
        <Items />
      </div>
    </div>
  ) : (
    <div style={styles.flex}>
      <div style={styles.leftCol}>
        <div style={styles.flex}>
          <ExchangeRate />
          <Items />
        </div>
      </div>
      <div style={styles.rightCol}>
        <div style={styles.flex}>
          <NewUsers />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
