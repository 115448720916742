import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  Edit,
  SimpleForm,
  Create,
  Filter,
  SelectInput,
  required,
} from 'react-admin';
import FlagField from '../components/FlagField';
import countries from '../assets/countries';

const BrandFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Поиск" source="q" alwaysOn />
  </Filter>
);

export const BrandList = (props) => (
  <List filters={<BrandFilter />} {...props} title={props.options.label}>
    <Datagrid rowClick="edit">
      <TextField source="name" label="Бренд" />
      <FlagField source="country" label="Страна регистрации" />
      <TextField source="manufacturer" label="Завод изготовитель" />
    </Datagrid>
  </List>
);

const BrandTitle = ({ record }) => {
  return <span>Бренд {record ? `"${record.name}"` : ''}</span>;
};

export const BrandEdit = (props) => (
  <Edit title={<BrandTitle />} {...props}>
    <SimpleForm>
      <TextInput source="name" label="Бренд" validate={[required()]} />
      <SelectInput
        source="country"
        label="Страна регистрации"
        choices={countries}
        validate={[required()]}
      />
      <FlagField source="country" label="Страна регистрации" />
      <TextInput
        source="manufacturer"
        label="Завод изготовитель"
        validate={[required()]}
      />
    </SimpleForm>
  </Edit>
);

export const BrandCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" label="Бренд" validate={[required()]} />
      <SelectInput
        source="country"
        label="Страна регистрации"
        choices={countries}
        validate={[required()]}
      />
      <TextInput
        source="manufacturer"
        label="Завод изготовитель"
        validate={[required()]}
      />
    </SimpleForm>
  </Create>
);
