import React from 'react';
import PropTypes from 'prop-types';
import Flag from 'react-flagkit';

const FlagField = ({ source, record = {} }) => {
  if (record.country) {
    return (
      <span>
        <Flag country={record.country} />
      </span>
    );
  }
  return <span>{record[source]}</span>;
};

FlagField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default FlagField;
