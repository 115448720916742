import * as React from 'react';

import { DateField, EditButton, TextField, useTranslate } from 'react-admin';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: { margin: '1em' },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '0.5rem 0',
  },
  cardTitleContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardContent: {
    ...theme.typography.body1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const MobileGrid = ({ ids, data, basePath }) => {
  const translate = useTranslate();
  const classes = useStyles();

  if (!ids || !data) {
    return null;
  }

  return (
    <div className={classes.root}>
      {ids.map((id) => (
        <Card key={id} className={classes.card}>
          <CardHeader
            title={
              <div className={classes.cardTitleContent}>
                <h2>
                  {translate('resources.rates.fields.name')}:
                  {` ${data[id].name}`}
                </h2>
                <EditButton
                  resource="rates"
                  basePath={basePath}
                  record={data[id]}
                />
              </div>
            }
          />
          <CardContent className={classes.cardContent}>
            <div>
              {translate('resources.rates.fields.cashRate')}:&nbsp;
              <TextField record={data[id]} source="cashRate" />
            </div>
            <div>
              {translate('resources.rates.fields.cashlessRate')}:&nbsp;
              <TextField record={data[id]} source="cashlessRate" />
            </div>
            <div>
              {translate('resources.rates.fields.date')}:&nbsp;
              <DateField record={data[id]} source="date" showTime />
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

MobileGrid.defaultProps = {
  data: {},
  ids: [],
};

export default MobileGrid;
