import * as React from 'react';

import {
  Box,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import {
  DateField,
  Edit,
  FormWithRedirect,
  Labeled,
  ReferenceField,
  TextField,
  Toolbar,
  useTranslate,
} from 'react-admin';

import Basket from './Basket';
import { Link as RouterLink } from 'react-router-dom';
import Totals from './Totals';
import { makeStyles } from '@material-ui/core/styles';

const OrderTitle = ({ record }) => {
  const translate = useTranslate();
  return record ? (
    <span>
      {translate('resources.commands.title', {
        reference: record.reference,
      })}
    </span>
  ) : null;
};

const CustomerDetails = ({ record }) => (
  <Box display="flex" flexDirection="column">
    <Typography
      component={RouterLink}
      color="primary"
      to={`/customers/${record?.id}`}
      style={{ textDecoration: 'none' }}
    >
      {record?.name} {record?.phone}
    </Typography>
    <Typography
      component={Link}
      color="primary"
      href={`mailto:${record?.email}`}
      style={{ textDecoration: 'none' }}
    >
      {record?.email}
    </Typography>
  </Box>
);

const CustomerAddress = ({ record }) => (
  <Box>
    <Typography>
      {record?.name} {record?.phone}
    </Typography>
    <Typography>{record?.address?.city}</Typography>
    <Typography>
      {record?.address?.post}, {record?.address?.postNumber}
    </Typography>
  </Box>
);

const useEditStyles = makeStyles({
  root: { alignItems: 'flex-start' },
});

const Spacer = () => <Box m={1}>&nbsp;</Box>;

const OrderForm = (props) => {
  const translate = useTranslate();
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Box maxWidth="70em">
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={8}>
                  <Typography variant="h6" gutterBottom>
                    {translate('resources.commands.section.order')}
                  </Typography>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled source="date" resource="commands" label="Дата">
                        <DateField
                          showTime
                          source="date"
                          resource="commands"
                          record={formProps.record}
                        />
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled
                        source="orderId"
                        resource="commands"
                        label="№ заказа"
                      >
                        <TextField
                          source="orderId"
                          resource="commands"
                          record={formProps.record}
                        />
                      </Labeled>
                    </Grid>
                  </Grid>
                  <Grid container>
                    {/* <Grid item xs={12} sm={12} md={6}>
                      <SelectInput
                        resource="commands"
                        source="status"
                        label="Статус"
                        choices={[
                          {
                            id: 'ordered',
                            name: 'Заказан',
                          },
                          {
                            id: 'delivered',
                            name: 'Доставлен',
                          },
                          {
                            id: 'cancelled',
                            name: 'Отменен',
                          },
                        ]}
                      />
                    </Grid> */}
                    {/* <Grid item xs={12} sm={12} md={6}>
                      <Box mt={2}>
                        <BooleanInput
                          row={true}
                          resource="commands"
                          source="returned"
                          label="Возврат"
                        />
                      </Box>
                    </Grid> */}
                    {/* {formProps.record.shippingMethod !== 'local' && (
                      <Grid item xs={12} sm={12} md={6}>
                        <Labeled
                          source="trackNumber"
                          resource="commands"
                          label="Трек номер"
                        >
                          <TextInput
                            source="trackNumber"
                            resource="commands"
                            record={formProps.record}
                            label="Трек номер"
                          />
                        </Labeled>
                      </Grid>
                    )} */}
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled
                        source="trackNumber"
                        resource="commands"
                        label="Примечание к заказу"
                      >
                        <TextField
                          source="notes"
                          resource="commands"
                          record={formProps.record}
                          label="Примечание к заказу"
                        />
                      </Labeled>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography variant="h6" gutterBottom>
                    {translate('resources.commands.section.customer')}
                  </Typography>
                  <ReferenceField
                    source="clientId"
                    resource="commands"
                    reference="customers"
                    basePath="/customers"
                    record={formProps.record}
                    link={false}
                  >
                    <CustomerDetails />
                  </ReferenceField>
                  <Spacer />

                  <Typography variant="h6" gutterBottom>
                    {translate('resources.commands.section.shipping_address')}
                  </Typography>
                  {formProps.record.shippingMethod === 'local' ? (
                    <TextField
                      emptyText="Самовывоз"
                      resource="commands"
                      record={formProps.record}
                    />
                  ) : (
                    <ReferenceField
                      source="clientId"
                      resource="commands"
                      reference="customers"
                      basePath="/customers"
                      record={formProps.record}
                      link={false}
                    >
                      <CustomerAddress />
                    </ReferenceField>
                  )}
                </Grid>
              </Grid>
              <Spacer />

              <Typography variant="h6" gutterBottom>
                {translate('resources.commands.section.items')}
              </Typography>
              <Box>
                <Basket record={formProps.record} />
              </Box>
              <Spacer />

              <Typography variant="h6" gutterBottom>
                {translate('resources.commands.section.total')}
              </Typography>
              <Box>
                <Totals record={formProps.record} />
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              undoable={true}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="commands"
            />
          </Card>
        </Box>
      )}
    />
  );
};
const OrderEdit = (props) => {
  const classes = useEditStyles();
  return (
    <Edit title={<OrderTitle />} classes={classes} {...props} component="div">
      <OrderForm />
    </Edit>
  );
};

export default OrderEdit;
