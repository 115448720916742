import * as React from 'react';

import AvatarField from './AvatarField';
import { makeStyles } from '@material-ui/core/styles';
import { memo } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}));

const FullNameField = ({ record, size }) => {
  const classes = useStyles();
  return record ? (
    <div className={classes.root}>
      <AvatarField className={classes.avatar} record={record} size={size} />
      {`${record.name} ${
        record.companyName && '(' + record.companyName + ' )'
      }`}
    </div>
  ) : null;
};

FullNameField.defaultProps = {
  source: 'name',
  label: 'resources.customers.fields.name',
};

export default memo(FullNameField);
