import * as React from 'react';

import FullNameField from './FullNameField';
import { ReferenceField } from 'react-admin';

const CustomerReferenceField = (props) => (
  <ReferenceField source="clientId" reference="customers" {...props}>
    <FullNameField />
  </ReferenceField>
);

CustomerReferenceField.defaultProps = {
  source: 'clientId',
  addLabel: true,
};

export default CustomerReferenceField;
