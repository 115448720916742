import {
  Create,
  Datagrid,
  Edit,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';

import React from 'react';

const ErrorCodesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Поиск" source="q" alwaysOn />
    <ReferenceInput
      label="Производитель"
      source="manufacturer_q"
      reference="manufacturers"
      allowEmpty
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const ErrorCodesList = (props) => (
  <List filters={<ErrorCodesFilter />} {...props} title={props.options.label}>
    <Datagrid rowClick="edit">
      <ReferenceField
        label="Производитель"
        source="manufacturer"
        reference="manufacturers"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" label="Ошибка" />
      <TextField source="description" label="Описание" fullWidth />
      <TextField source="type" label="Тип" />
    </Datagrid>
  </List>
);

const ErrorCodesTitle = ({ record }) => {
  return <span>Код {record ? `"${record.name}"` : ''}</span>;
};

export const ErrorCodesEdit = (props) => (
  <Edit title={<ErrorCodesTitle />} {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Производитель"
        source="manufacturer"
        reference="manufacturers"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" label="Ошибка" />
      <TextInput source="description" label="Описание" fullWidth />
      <TextInput source="type" label="Тип" />
    </SimpleForm>
  </Edit>
);

export const ErrorCodesCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Производитель"
        source="manufacturer"
        reference="manufacturers"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" label="Ошибка" />
      <TextInput source="description" label="Описание" />
      <TextInput source="type" label="Тип" />
    </SimpleForm>
  </Create>
);
