import { Title, useLocale, useSetLocale, useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Link } from 'react-router-dom';
import React from 'react';
import { changeTheme } from './actions';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
  buttonText: { marginLeft: '1em' },
});

const Configuration = () => {
  const translate = useTranslate();
  const locale = useLocale();
  const setLocale = useSetLocale();
  const classes = useStyles();
  const theme = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  return (
    <Card>
      <Title title={translate('pos.configuration')} />
      <CardContent>
        <div className={classes.label}>{translate('pos.theme.name')}</div>
        <Button
          variant="contained"
          className={classes.button}
          color={theme === 'light' ? 'primary' : 'default'}
          onClick={() => dispatch(changeTheme('light'))}
        >
          {translate('pos.theme.light')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color={theme === 'dark' ? 'primary' : 'default'}
          onClick={() => dispatch(changeTheme('dark'))}
        >
          {translate('pos.theme.dark')}
        </Button>
      </CardContent>
      <CardContent>
        <div className={classes.label}>{translate('pos.language')}</div>
        {/* <Button
          variant="contained"
          className={classes.button}
          color={locale === 'en' ? 'primary' : 'default'}
          onClick={() => setLocale('en')}
        >
          en
        </Button> */}
        <Button
          variant="contained"
          className={classes.button}
          color={locale === 'ru' ? 'primary' : 'default'}
          onClick={() => setLocale('ru')}
        >
          ru
        </Button>
        {/* <Button
          variant="contained"
          className={classes.button}
          color={locale === 'ua' ? 'primary' : 'default'}
          onClick={() => setLocale('ua')}
        >
          ua
        </Button> */}
      </CardContent>
      <CardContent>
        <div className={classes.label}>{translate('pos.configure_users')}</div>
        <Button
          variant="contained"
          className={classes.button}
          component={Link}
          to={{
            pathname: '/users',
            // search: `?post_id=${record.id}`,
          }}
          label={translate('pos.configure_users')}
          title={translate('pos.configure_users')}
        >
          <AccountCircleIcon />{' '}
          <span className={classes.buttonText}>
            {translate('pos.users_link')}
          </span>
        </Button>
      </CardContent>
    </Card>
  );
};

export default Configuration;
