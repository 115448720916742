import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  Edit,
  SimpleForm,
  Create,
  Filter,
  ImageField,
  ImageInput,
  SelectInput,
  NumberInput,
  required,
} from 'react-admin';

const ManufacturersFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Поиск" source="q" alwaysOn />
  </Filter>
);

export const ManufacturersList = (props) => (
  <List
    filters={<ManufacturersFilter />}
    {...props}
    title={props.options.label}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" label="Производитель" />
      <TextField source="currency" label="Валюта" />
      <ImageField source="image" label="Фото" className="manufacturers_image" />
      <TextField source="order" label="Порядок" />
    </Datagrid>
  </List>
);

const ManufacturersTitle = ({ record }) => {
  return <span>Производитель {record ? `"${record.name}"` : ''}</span>;
};

export const ManufacturersEdit = (props) => (
  <Edit title={<ManufacturersTitle />} {...props}>
    <SimpleForm>
      <TextInput source="name" label="Производитель" validate={[required()]} />
      <SelectInput
        source="currency"
        initialValue="$"
        label="Валюта"
        validate={[required()]}
        choices={[
          { id: '$', name: '$' },
          { id: '€', name: '€' },
          { id: 'грн', name: 'грн' },
        ]}
      />
      <NumberInput
        source="order"
        label="Порядок"
        min={1}
        max={99}
        step={1}
        validate={[required()]}
      />
      <ImageField source="image" label="Текущее Фото" />
      <ImageInput
        source="image"
        label="Новое Фото"
        accept="image/*"
        multiple={false}
      >
        <ImageField source="image" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export const ManufacturersCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" label="Производитель" validate={[required()]} />
      <SelectInput
        source="currency"
        initialValue="$"
        label="Валюта"
        validate={[required()]}
        choices={[
          { id: '$', name: '$' },
          { id: '€', name: '€' },
          { id: 'грн', name: 'грн' },
        ]}
      />
      <NumberInput
        source="order"
        label="Порядок"
        min={1}
        max={99}
        step={1}
        initialValue={99}
        validate={[required()]}
      />
      <ImageInput
        source="image"
        label="Фото"
        accept="image/*"
        multiple={false}
        validate={[required()]}
      >
        <ImageField source="image" />
      </ImageInput>
    </SimpleForm>
  </Create>
);
