import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  Edit,
  SimpleForm,
  Create,
  Filter,
  ImageInput,
  ImageField,
  required,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const ArticlesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Поиск" source="q" alwaysOn />
  </Filter>
);

export const ArticlesList = (props) => (
  <List filters={<ArticlesFilter />} {...props} title={props.options.label}>
    <Datagrid rowClick="edit">
      <TextField source="name" label="Название" />
      <ImageField source="image" label="Фото" className="articles_image" />
    </Datagrid>
  </List>
);

const ArticlesTitle = ({ record }) => {
  return <span>Статья {record ? `"${record.name}"` : ''}</span>;
};

export const ArticlesEdit = (props) => (
  <Edit title={<ArticlesTitle />} {...props}>
    <SimpleForm>
      <TextInput source="name" label="Название" validate={[required()]} />
      <ImageField source="image" label="Текущее Фото" />
      <ImageInput
        source="image"
        label="Новое Фото"
        accept="image/*"
        multiple={false}
      >
        <ImageField source="image" />
      </ImageInput>
      <RichTextInput source="body" label="Содержимое" validate={[required()]} />
    </SimpleForm>
  </Edit>
);

export const ArticlesCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" label="Название" validate={[required()]} />
      <ImageInput
        source="image"
        label="Фото"
        accept="image/*"
        multiple={false}
        validate={[required()]}
      >
        <ImageField source="image" />
      </ImageInput>
      <RichTextInput source="body" label="Содержимое" validate={[required()]} />
    </SimpleForm>
  </Create>
);
