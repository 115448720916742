import * as React from 'react';

const PhoneField = (props) => {
  const renderPhoneNumber = () => {
    if (props?.record?.phone) {
      const x = props.record.phone
        .replace(/\D/g, '')
        .match(/(\d{3})(\d{3})(\d{4})/);
      if (x) {
        return '(' + x[1] + ') ' + x[2] + '-' + x[3];
      }
      return props.record.phone;
    }
    return '';
  };
  return (
    <span className="MuiTypography-root MuiTypography-body2">
      {renderPhoneNumber()}
    </span>
  );
};

export default PhoneField;
