import { useQueryWithStore, useTranslate } from 'react-admin';

import Card from '@material-ui/core/Card';
import CardIcon from './CardIcon';
import DollarIcon from '@material-ui/icons/AttachMoney';
import { Link } from 'react-router-dom';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  main: {
    flex: '1',
    marginRight: '1em',
    marginTop: 20,
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 52,
  },
  title: {
    // lineHeight: '14px',
  },
  titleLink: { textDecoration: 'none', color: 'inherit' },
});

const location = {
  pathname: 'rates',
  query: {},
};

const ExchangeRate = () => {
  const translate = useTranslate();
  const classes = useStyles();

  const { loaded, data: rate } = useQueryWithStore({
    type: 'getList',
    resource: 'rates',
    payload: {
      sort: { field: 'id', order: 'ASC' },
      pagination: { page: 1, perPage: 50 },
    },
  });

  if (!loaded) return null;

  return (
    <div className={classes.main}>
      <CardIcon Icon={DollarIcon} bgColor="#31708f" />
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
          {translate('pos.dashboard.exchange_rate')}
        </Typography>
        <Typography variant="subtitle1" component="h2">
          <Link to={location} className={classes.titleLink}>
            {`${rate[0]?.name} ${rate[0]?.cashRate} / ${rate[0]?.cashlessRate}`}
          </Link>
        </Typography>
        <Typography variant="subtitle1" component="h2">
          <Link to={location} className={classes.titleLink}>
            {`${rate[1]?.name} ${rate[1]?.cashRate} / ${rate[1]?.cashlessRate}`}
          </Link>
        </Typography>
      </Card>
    </div>
  );
};

export default ExchangeRate;
