import {
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  Edit,
  Filter,
  FormDataConsumer,
  FormTab,
  ImageField,
  ImageInput,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  required,
} from 'react-admin';

import Divider from '@material-ui/core/Divider';
import { InputAdornment } from '@material-ui/core';
import React from 'react';

const ItemsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Поиск" source="q" alwaysOn />
    <ReferenceInput
      label="Производитель"
      source="manufacturer_q"
      reference="consumables-manufacturers"
      allowEmpty
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Категория"
      source="category_q"
      reference="consumables-categories"
      allowEmpty
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    {/* <SelectInput
      source="type_q"
      label="Тип"
      allowEmpty={false}
      choices={[
        { id: 'common', name: 'Бытовые кондиционеры' },
        { id: 'multi', name: 'Мульти-сплит системы' },
        { id: 'commercial', name: 'Коммерческие кондиционеры' },
      ]}
    /> */}
  </Filter>
);

export const ConsumablesItemsList = (props) => (
  <List filters={<ItemsFilter />} {...props} title={props.options.label}>
    <Datagrid rowClick="edit">
      <TextField source="name" label="Название товара" />
      <ReferenceField
        label="Категория"
        source="category"
        reference="consumables-categories"
      >
        <ChipField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Производитель"
        source="manufacturer"
        reference="consumables-manufacturers"
      >
        <TextField source="name" />
      </ReferenceField>
      {/* <SelectField
        source="typeCat"
        label="Тип"
        choices={[
          { id: 'common', name: 'Бытовые кондиционеры' },
          { id: 'multi', name: 'Мульти-сплит системы' },
          { id: 'commercial', name: 'Коммерческие кондиционеры' },
        ]}
      /> */}
      <TextField source="optPrice" label="Цена, опт." />
      <TextField source="price" label="Цена, розн." />
      <TextField source="stock1" label="Наличие, Сумы" />
      <TextField source="stock2" label="Наличие, Украина" />
      <TextField source="order" label="Порядок" />
    </Datagrid>
  </List>
);

const ItemsTitle = ({ record }) => {
  return <span>Товар {record ? `"${record.name}"` : ''}</span>;
};

export const ConsumablesItemsEdit = (props) => (
  <Edit title={<ItemsTitle />} {...props}>
    <TabbedForm>
      <FormTab label={'resources.items.tabs.main'}>
        <ReferenceInput
          label="Производитель"
          source="manufacturer"
          reference="consumables-manufacturers"
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        {/* <SelectInput
          source="typeCat"
          label="Тип"
          choices={[
            { id: 'common', name: 'Бытовые кондиционеры' },
            { id: 'multi', name: 'Мульти-сплит системы' },
            { id: 'commercial', name: 'Коммерческие кондиционеры' },
          ]}
          validate={[required()]}
        /> */}
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.manufacturer ? (
              <ReferenceInput
                label="Категория"
                source="category"
                reference="consumables-categories"
                perPage={100}
                filter={{ manufacturer_q: formData.manufacturer }}
                validate={[required()]}
                {...rest}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            ) : null
          }
        </FormDataConsumer>
        <TextInput
          source="name"
          label="Название товара"
          validate={[required()]}
        />
        <BooleanInput label="Цена, опт по запросу" source="useOptPriceText" />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
              <NumberInput
                source="optPrice"
                label="Цена, опт."
                format={(v) => parseFloat(v)}
                parse={(v) => v.replace(/,/g, '.').toString()}
                initialValue={0}
                validate={[required()]}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                disabled={formData.useOptPriceText}
              />
          }
        </FormDataConsumer>
        <NumberInput
          source="price"
          label="Цена, розн."
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString()}
          initialValue={0}
          validate={[required()]}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <NumberInput
          source="order"
          label="Порядок"
          min={1}
          max={99}
          step={1}
          validate={[required()]}
        />
        <Divider />
        <p>Склад</p>
        <NumberInput
          source="stock1"
          label="Наличие, Сумы"
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString().split('.')[0]}
          min={0}
          step={1}
          initialValue={0}
          validate={[required()]}
        />
        <NumberInput
          source="stock2"
          label="Наличие, Украина"
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString().split('.')[0]}
          min={0}
          step={1}
          initialValue={0}
          validate={[required()]}
        />
      </FormTab>
      <FormTab label="resources.items.tabs.details" path="details">
        <TextInput multiline fullWidth source="description" label="Описание" />
      </FormTab>
      <FormTab label="resources.items.tabs.photo" path="photo">
        <ImageField source="image" label="Текущее Фото" />
        <ImageInput
          source="image"
          label="Новое Фото"
          accept="image/*"
          multiple={false}
        >
          <ImageField source="image" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const ConsumablesItemsCreate = (props) => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label={'resources.items.tabs.main'}>
        <ReferenceInput
          label="Производитель"
          source="manufacturer"
          reference="consumables-manufacturers"
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.manufacturer ? (
              <ReferenceInput
                label="Категория"
                source="category"
                reference="consumables-categories"
                perPage={100}
                filter={{ manufacturer_q: formData.manufacturer }}
                validate={[required()]}
                {...rest}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            ) : null
          }
        </FormDataConsumer>
        <TextInput
          source="name"
          label="Название товара"
          validate={[required()]}
        />
        <BooleanInput label="Цена, опт по запросу" source="useOptPriceText" />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
              <NumberInput
                source="optPrice"
                label="Цена, опт."
                format={(v) => parseFloat(v)}
                parse={(v) => v.replace(/,/g, '.').toString()}
                initialValue={0}
                validate={[required()]}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                disabled={formData.useOptPriceText}
              />
          }
        </FormDataConsumer>
        <NumberInput
          source="price"
          label="Цена, розн."
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString()}
          initialValue={0}
          validate={[required()]}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <NumberInput
          source="order"
          label="Порядок"
          min={1}
          max={99}
          step={1}
          validate={[required()]}
        />
        <Divider />
        <p>Склад</p>
        <NumberInput
          source="stock1"
          label="Наличие, Сумы"
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString().split('.')[0]}
          min={0}
          step={1}
          initialValue={0}
          validate={[required()]}
        />
        <NumberInput
          source="stock2"
          label="Наличие, Украина"
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString().split('.')[0]}
          min={0}
          step={1}
          initialValue={0}
          validate={[required()]}
        />
      </FormTab>
      <FormTab label="resources.items.tabs.details" path="details">
        <TextInput multiline fullWidth source="description" label="Описание" />
      </FormTab>
      <FormTab label="resources.items.tabs.photo" path="photo">
        <ImageInput
          source="image"
          label="Фото"
          accept="image/*"
          multiple={false}
        >
          <ImageField source="image" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Create>
);
