import {
  ArrayField,
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  NumberInput,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  required,
} from 'react-admin';

import MobileGrid from './MobileGrid';
import React from 'react';
import { useMediaQuery } from '@material-ui/core';

export const RateList = (props) => {
  const isXsmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  return (
    <List bulkActionButtons={false} {...props} title={props.options.label}>
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid rowClick="edit">
          <TextField source="name" label="Валюта" />
          <TextField source="cashRate" label="Нал" />
          <TextField source="cashlessRate" label="Безнал" />
          <DateField source="date" label="Дата изменения" showTime />
        </Datagrid>
      )}
    </List>
  );
};

const RateTitle = ({ record }) => {
  return <span>Валюта {record ? `"${record.name}"` : ''}</span>;
};

const RateEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const RateEdit = (props) => (
  <Edit title={<RateTitle />} {...props}>
    <SimpleForm toolbar={<RateEditToolbar />}>
      <TextInput
        label="Валюта"
        source="name"
        validate={[required()]}
        disabled
      />
      <NumberInput
        label="Нал"
        source="cashRate"
        step={0.01}
        format={(v) => parseFloat(v)}
        parse={(v) => v.replace(/,/g, '.').toString()}
        initialValue={25}
        validate={[required()]}
      />
      <NumberInput
        label="Безнал"
        source="cashlessRate"
        step={0.01}
        format={(v) => parseFloat(v)}
        parse={(v) => v.replace(/,/g, '.').toString()}
        initialValue={25}
        validate={[required()]}
      />
      <ArrayField source="history" label="История">
        <Datagrid>
          <DateField source="date" label="Дата" />
          <TextField source="cashRate" label="Нал" />
          <TextField source="cashlessRate" label="Безнал" />
        </Datagrid>
      </ArrayField>
    </SimpleForm>
  </Edit>
);

export const RateCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Валюта" source="name" validate={[required()]} />
      <NumberInput
        label="Нал"
        source="cashRate"
        step={0.01}
        format={(v) => parseFloat(v)}
        parse={(v) => v.replace(/,/g, '.').toString()}
        initialValue={25}
        validate={[required()]}
      />
      <NumberInput
        label="Безнал"
        source="cashlessRate"
        step={0.01}
        format={(v) => parseFloat(v)}
        parse={(v) => v.replace(/,/g, '.').toString()}
        initialValue={25}
        validate={[required()]}
      />
    </SimpleForm>
  </Create>
);
