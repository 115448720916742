import React, { useMemo } from 'react';
import { useQueryWithStore, useTranslate } from 'react-admin';

import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardIcon from './CardIcon';
import CustomerIcon from '@material-ui/icons/PersonAdd';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  main: {
    flex: '1',
    marginTop: 20,
  },
  card: {
    padding: '16px 0',
    overflow: 'inherit',
    textAlign: 'right',
  },
  title: {
    padding: '0 16px',
  },
  value: {
    padding: '0 16px',
    minHeight: 48,
  },
});

const NewUsers = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const aMonthAgo = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }, []);

  const formatDate = (d) => {
    const date = new Date(d);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }
    return `${day}.${month}.${year}`;
  };

  const { loaded, data: visitors } = useQueryWithStore({
    type: 'getList',
    resource: 'customers',
    payload: {
      filter: {
        first_seen_gte: aMonthAgo.toISOString(),
      },
      sort: { field: 'signUpDate', order: 'DESC' },
      pagination: { page: 1, perPage: 10 },
    },
  });

  if (!loaded) return null;

  const nb = visitors ? visitors.reduce((nb) => ++nb, 0) : 0;
  return (
    <div className={classes.main}>
      <CardIcon Icon={CustomerIcon} bgColor="#4caf50" />
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
          {translate('pos.dashboard.new_users')}
        </Typography>
        <Typography variant="h5" component="h2" className={classes.value}>
          {nb}
        </Typography>
        <Divider />
        <List>
          {visitors
            ? visitors.map((record) => (
                <ListItem
                  button
                  to={`/customers/${record.id}`}
                  component={Link}
                  key={record.id}
                >
                  <ListItemAvatar>
                    <Avatar />
                  </ListItemAvatar>
                  <ListItemText primary={`${record.name} ${record.phone}`} />
                  <ListItemSecondaryAction>
                    <span className={classes.cost}>
                      {formatDate(record.signUpDate)}
                    </span>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            : null}
        </List>
      </Card>
    </div>
  );
};

export default NewUsers;
