import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  Edit,
  SimpleForm,
  Create,
  Filter,
  ImageField,
  ImageInput,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  required,
} from 'react-admin';

const PhotosFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Поиск" source="q" alwaysOn />
    <ReferenceInput
      label="Производитель"
      source="manufacturer_q"
      reference="manufacturers"
      allowEmpty
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const PhotosList = (props) => (
  <List filters={<PhotosFilter />} {...props} title={props.options.label}>
    <Datagrid rowClick="edit">
      <TextField source="name" label="Серия" />
      <ReferenceField
        label="Производитель"
        source="manufacturer"
        reference="manufacturers"
      >
        <TextField source="name" />
      </ReferenceField>
      <ImageField source="image" label="Фото" className="manufacturers_image" />
    </Datagrid>
  </List>
);

const PhotosTitle = ({ record }) => {
  return <span>Фото {record ? `"${record.name}"` : ''}</span>;
};

export const PhotosEdit = (props) => (
  <Edit title={<PhotosTitle />} {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Производитель"
        source="manufacturer"
        reference="manufacturers"
        validate={[required()]}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" label="Серия" validate={[required()]} />
      <ImageField source="image" label="Текущее Фото" />
      <ImageInput
        source="image"
        label="Новое Фото"
        accept="image/*"
        multiple={false}
      >
        <ImageField source="image" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export const PhotosCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Производитель"
        source="manufacturer"
        reference="manufacturers"
        validate={[required()]}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" label="Серия" validate={[required()]} />
      <ImageInput
        source="image"
        label="Фото"
        accept="image/*"
        multiple={false}
        validate={[required()]}
      >
        <ImageField source="image" />
      </ImageInput>
    </SimpleForm>
  </Create>
);
